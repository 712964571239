@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  background: linear-gradient(135deg, #EDEDE9, #E3D5CA); /* Updated gradient */
  color: #333; /* Replacing #333 */
  min-height: 800px;
}

h1, h2, h3 {
  font-family: 'Montserrat', sans-serif;
  color: #555; /* Replacing #A6E1FA */
  font-size: calc(1.1rem + .6vw);
}

p {
  font-family: 'Montserrat', sans-serif;
  color: #333; /* Replacing #000000 */
  ;
}

a{
  font-family: 'Montserrat', sans-serif;
  text-decoration-color: #E3D5CA;
}

.glass {
  background: rgba(248, 237, 235, 0.1); /* Replacing rgba(255, 255, 255, 0.1) */
  backdrop-filter: blur(10px);
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 181, 167, 0.2); /* Updated border color */
  animation: fadeInUp 1s ease-in-out;
}


.custom-link {
  text-decoration: none; /* Remove underline */
  color: #c38857; /* Set link color */
}

.custom-link p{
  color: #5f3d21; /* Set link color */
  text-decoration: underline;
}

