/* Remove default margins and padding */
body, html {
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
}

.video-section {
  position: relative;
  height: 90vh; /* Adjusted for better balance on desktop */
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  align-items: center; /* Center the video vertically */
  justify-content: center; /* Center the video horizontally */
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-fluid {
  width: 100%;
  height: auto;
  max-height: 90vh; /* Prevents the video from stretching too high */
  object-fit: cover; /* Ensures the video covers the entire section without distortion */
}

/* Text Overlay with glassy background */
.video-overlay {
  position: absolute;
  top: 50%; /* Center the overlay vertically */
  left: 50%;
  transform: translate(-50%, -50%); /* Center alignment */
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  width: 80%;
  max-width: 800px;
  padding: 15px;
  border-radius: 15px;
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.45);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

/* Text styles */
.video-overlay h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
}

.video-overlay p {
  font-size: 1.25rem;
  margin: 10px 0 0;
}

/* Media query for smaller devices */
@media (max-width: 768px) {
  .video-section {
    height: 70vh; /* Adjusted height for smaller screens */
  }

  .video-overlay h2 {
    font-size: 1.75rem;
  }

  .video-overlay p {
    font-size: 1rem;
  }

  .video-overlay {
    width: 90%;
    padding: 10px;
  }
}



/* Arrow Button */
.scroll-down-arrow {
  position: absolute;
  bottom: 40px; /* Position at the bottom of the video */
  left: 50%;
  transform: translateX(-50%);
  font-size: 6rem; /* Large arrow size */
  color: rgba(255, 255, 255, 0.8);
  background: none;
  border: none;
  cursor: pointer;
  animation: bounce 2s infinite; /* Adds a bouncing animation */
}

/* Bouncing animation for arrow */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translate(-50%, 0);
  }
  40% {
    transform: translate(-50%, -10px);
  }
  60% {
    transform: translate(-50%, -5px);
  }
}

.scroll-down-arrow:hover {
  color: rgba(255, 255, 255, 1); /* Brighter on hover */
}

.video-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%; /* Only covers the bottom 20% */
  background: linear-gradient(to top, rgba(221, 215, 209, 1), transparent);
  pointer-events: none; /* Allows interaction with the video */
  z-index: 3;
}