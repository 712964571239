body {
  background: linear-gradient(135deg, #F5EBE0, #E3D5CA); /* Replacing #A6E1FA, #FEC8D8 */
  /* font-family:  'Montserrat', 'Poppins', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  color: #333; /* Replacing #333 */
}

h2, h3 {
  font-family: 'Montserrat', sans-serif;
  color: #555; /* Replacing #A6E1FA */
}

p {
  font-family: 'Montserrat', sans-serif;
  color: #333; /* Replacing #555 */
}
