.action-bar {
  background-color: #D5BDAF; /* Replacing #212529f7 */
  padding: 10px 0;
  z-index: 1030;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-link {
  color: #333; /* Replacing #28a745 (green) */
  font-size: 16px;
  text-decoration: none;
}

.action-link i {
  margin-right: 5px;
}

.action-link:hover {
  color: #F5EBE0; /* Replacing #1e7e34 (darker green) */
}

/* Navbar custom styles */
.navbar-custom {
  transition: all 0.3s ease-in-out;
  background-color: #e3d5cadf(227, 213, 202, 0.8); 
}

.navbar-brand-light, .nav-link-light {
  color: #EDEDE9 !important; /* Replacing rgba(255, 255, 255, 0.9) */
  font-weight: 600;
}

.navbar-brand-light:hover, .nav-link-light:hover {
  color: #D5BDAF !important; /* Replacing rgba(255, 255, 255, 1) */
  text-decoration: none;
}

/* Media query for mobile - Adjust the font size slightly */
@media (max-width: 767px) {
  .action-link {
    font-size: 14px; /* Slightly smaller text on mobile */
  }
}
.justify-content-center {
  justify-content: center;
}

.apt{
  font-weight: 500 !important;
  color: #ebebeb !important;
}