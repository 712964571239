.appointment-page {
    padding: 40px;
    text-align: center;
  }
  
  .square-appointments-widget {
    margin-top: 30px;
    /* border-color: #cecece; */
  }
  
  iframe.embed-responsive-item{
    border-radius: 5px;
  }

  /* market-row.service-row.border-gray-100.border-2.market-row.preload:hover {
    background: #e8ded540 !important;
} */