



  @media only screen and (min-width: 768px) {
    .map-form{
      padding: 30px;
      flex:1;
    }
  } 

  
  .map-container {
    margin-top: 20px;
  }

  h3 {
    margin-bottom: 20px;
  }
  
  .error-message {
    color: #db2e2e; /* Replacing red */
    margin-top: 10px;
  }
  
  .vertical-divider {
    width: 2px; /* Thickness of the divider */
    height: 100%; /* Full height of the parent container */
    background-color: #211c1c; /* Light grey color */
    margin: 0 20px; /* Spacing around the divider */
  }