/* Home Page Container */
.home {
  padding: 0;
  position: relative;
  z-index: 1;
}

/* Background Overlay */
.home::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("/public/images/bg3.jpg");
  background-size: cover;
  background-position: center;
  opacity: 0.4;
  z-index: -1;
}

/* General Image Styling */
.img-fluid {
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
  max-height: 350px;
  margin-bottom: 1rem;
  max-width: 350px !important;
  cursor: pointer;
}

/* About, Services, and Contact Sections */
.about-section, .services-section, .contact-section {
  padding: 20px 0;
  text-align: center;
}

/* Contact Section Flex Layout */
.contactsection {
  display: flex;
  align-items: center;
}

/* Rounded Images */
.rounded-image {
  width: 100px;
  height: auto;
  border-radius: 15px;
  margin-bottom: 20px;
}

/* Glass Styling */
.glass {
  padding: 20px;
  margin: 20px;
  background: rgba(248, 237, 235, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Contact Form Styling */
.contact-form {
  margin: 0 auto;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #D6CCC2;
}

/* Button Styling */
.btn {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #d2a185;
  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
}

.btn:hover {
  background-color: #f3b18c;
}

.btn.btn-primary {
  background: #d1a48a;
}

/* Headings */
.h2, h2 {
  text-align: center;
}

/* Link Styling */
a {
  text-decoration-color: #424242;
}

/* Testimonials Section */
.blockquote {
  font-size: 1.1rem;
  line-height: 1.5;
  font-style: italic;
  color: #D6CCC2;
}

/* Gallery Item Styling */
.gallery-item {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  filter: brightness(0.5);
}

.gallery-item img {
  width: 100%;
  max-width: 100%;
  height: auto;
  transition: transform 0.2s ease-in-out;
}


img.logo-center {
  z-index: 5;
}

.gallery-item img:hover {
  transform: scale(1.05);
}

.img-fluid.rounded {
  width: 220px;
  height: 120px;
}

/* Lightbox Styling */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
}

.close {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 30px;
  color: white;
  cursor: pointer;
}

.lightbox-content {
  display: flex;
  align-items: center;
  flex-direction: column; /* Vertical layout for arrows */
  cursor: default;
}

.lightbox-image {
  max-width: 90%;
  max-height: 80vh;
  margin: 10px 0;
}

.lightbox-arrow {
  font-size: 2rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

.up-arrow {
  margin-bottom: 10px;
}

.down-arrow {
  margin-top: 10px;
}

.lightbox-arrow:hover {
  color: rgba(255, 255, 255, 0.8);
}

/* Logo Overlay for Gallery */
.logo-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  cursor: pointer;
}

.logo-center {
  width: 100px !important;
  height: 100px !important;
}

/* Carousel Indicators */
.carousel-indicators {
  display: none;
}

/* Divider Styling */
.divider {
  max-width: 800px;
  position: relative;
  margin: auto;
}

.divider.grid {
  margin-bottom: 20px;
}
