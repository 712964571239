.service-page {
    padding: 30px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  h1, h2 {
    color: #c38857;
  }

  .h2, h2 {
    text-align: center;
  }
  
  p {
    color: #333;
    font-size: 1.1rem;
  }
  
  .price-list ul {
    list-style-type: none;
    padding: 0;
    text-align: center;

  }
  
  .price-list ul li {
    margin-bottom: 10px;
    font-size: 1.1rem;
    color: #c77023;
  }
  