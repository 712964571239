.footer {
  text-align: center;
  padding: 10px;
  color: #EDEDE9; /* Replacing #333 */
  background: #212529; /* Replacing #212529 */
}

.footer a {
  color: #d7ccc16e; /* Replacing #65cc0b */
  text-decoration: underline;
  text-decoration-color: #d6ccc247 !important;
}

.footer p {
  color: #D6CCC2; /* Replacing #ccc */
}




/* Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover {
  color: #ccc;
}


